/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { isEmpty, merge } from 'lodash/fp'

type GenericObject = Record<string, string | number>

interface Props {
  title: string
  description?: string
  lang?: string
  links?: GenericObject[]
  meta?: GenericObject[]
  children?: React.ReactNode[] | React.ReactNode
}

const SEO: React.FC<Props> = ({
  description,
  lang,
  links,
  meta,
  title,
  children,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaDefaultObjects = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  const metaObjects = !isEmpty(meta)
    ? merge(metaDefaultObjects, meta)
    : metaDefaultObjects

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={links}
      meta={metaObjects}
    >
      <html lang={lang} />
      {children}
      <body className={'site-body'} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `sv`,
  meta: [],
  description: ``,
}

export default SEO
